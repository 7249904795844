import React from "react"
import Container from "../components/container"
import Seo from "../components/Seo"
import CustomHeader from "../components/customHeader"
import SideBarView from "../components/SideBarView"
import dateFormat from "dateformat"
import { DataGrid } from "@mui/x-data-grid"
import { Typography, Box, Grid } from "@mui/material"
import { Link as GatsbyLink } from "gatsby"
import { styled } from "@mui/system"


const Link = styled(props => <GatsbyLink {...props} />)({
  color: "#69c",
  textDecoration: "none",
  "&:hover": { color: "#8fc9ff" }
})

// eslint-disable-next-line no-extend-native
Date.prototype.getWeek = function(dowOffset) {
  dowOffset = typeof (dowOffset) == "number" ? dowOffset : 0 //default dowOffset to zero
  const newYear = new Date(this.getFullYear(), 0, 1)
  let day = newYear.getDay() - dowOffset //the day of week the year begins on
  day = (day >= 0 ? day : day + 7)
  const daynum = Math.floor((this.getTime() - newYear.getTime() -
    (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1
  let weeknum
  //if the year starts before the middle of a week
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1
    if (weeknum > 52) {
      const nYear = new Date(this.getFullYear() + 1, 0, 1)
      let nday = nYear.getDay() - dowOffset
      nday = nday >= 0 ? nday : nday + 7
      /*if the next year starts before the middle of
        the week, it is week #1 of that year*/
      weeknum = nday < 4 ? 1 : 53
    }
  } else {
    weeknum = Math.floor((daynum + day - 1) / 7)
  }
  return weeknum
}

const isBrowser = () => typeof window !== "undefined"
const WeeksPage = (props) => {
  const year = props?.pageContext?.year
  const currentYear = (new Date()).getFullYear()
  if (isNaN(year) || year < 1900) {
    isBrowser() && (window.location = `/weeks/${currentYear}`)
    return null
  }
  const startOfYear = new Date(year, 0, 1)
  const endOfYear = new Date(year, 11, 31)
  const endOfYearTimestamp = endOfYear.getTime()
  const totalWeeks = endOfYear.getWeek()
  const weekOfSet = (startOfYear.getDay() || 7) - 1
  const datesList = []
  let tempStartDate = new Date(startOfYear)
  if (weekOfSet) {
    tempStartDate.setDate(startOfYear.getDate() - weekOfSet)
    const tempEndDate = new Date(tempStartDate)
    tempEndDate.setDate(tempStartDate.getDate() + 6)
    const startDate = dateFormat(tempStartDate, "mmmm dS, yyyy")
    const endDate = dateFormat(tempEndDate, "mmmm dS, yyyy")
    datesList.push({ weekNumber: `Week 52, ${year - 1}`, startDate, endDate, timestamp: tempStartDate.getTime() })
  }

  let counter = 1
  while (endOfYearTimestamp >= tempStartDate.getTime()) {
    tempStartDate.setDate(tempStartDate.getDate() + 7)
    const tempEndDate = new Date(tempStartDate)
    tempEndDate.setDate(tempStartDate.getDate() + 6)
    const startDate = dateFormat(tempStartDate, "mmmm dS, yyyy")
    const endDate = dateFormat(tempEndDate, "mmmm dS, yyyy")
    if (counter >= 52 && tempStartDate.getFullYear() === (Number(year) + 1)) {
      datesList.push({
        weekNumber: `Week 1, ${Number(year) + 1}`,
        startDate,
        endDate,
        timestamp: tempStartDate.getTime()
      })
      continue
    }
    datesList.push({
      weekNumber: `Week ${String(counter++).padStart(2, "0")}`,
      startDate,
      endDate,
      timestamp: tempStartDate.getTime()
    })
  }

  const columns = [
    {
      headerName: "Week Number",
      field: "weekNumber",
      key: "weekNumber",
      fixed: "left",
      flex: 1
    },
    {
      headerName: "From Date",
      field: "startDate",
      key: "startDate",
      flex: 1
    },
    {
      headerName: "To Date",
      field: "endDate",
      key: "endDate",
      flex: 1
    }
  ]

  return (
    <Container>
      <CustomHeader title={`Week Numbers for ${year}`}/>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          <div style={{ marginTop: "20px" }}>
            <p>
              This page lists all weeks in {year}. There are {totalWeeks} weeks in {year}.<br/>
              <br/>
              All weeks are starting on Monday and ending on Sunday.<br/>
              Please note that there are multiple systems for week numbering, this is the ISO week date standard<br/>
              (ISO-8601), other systems use weeks starting on Sunday (US) or Saturday (Islamic).<br/>
              <br/>
              {(currentYear === year) ? (
                <p>The current week is <b>week {String((new Date()).getWeek()).padStart(2, "0")} </b>.</p>) : null}<br/>
            </p>
            <Link to={`/weeks/${year - 1}`}>« week numbers for {year - 1}</Link> | <Link to={`/weeks/${year - 0 + 1}`}>week
            numbers for {year - 0 + 1} »</Link>
          </div>
          <Typography
            variant={`h2`}
            style={{
              // color: "#4d3267",
              fontSize: "24px",
              fontWeight: "bold",
              fontFamily: "Karla, sans-serif"
            }}
          >
            List of weeks with start and end date
          </Typography>
          <div>
            <Box sx={{ height: 600, width: "100%" }}>
              <DataGrid getRowId={row => row.timestamp} rows={datesList} columns={columns} rowKey={"timestamp"}/>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3}>
          <SideBarView/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default WeeksPage

export const Head = (props) => {
  const year = props?.pageContext?.year
  const siteMetadata = {
    description: "List of weeks for an year with week numbers, week start and end dates",
    description2: "",
    keywordsContent: "week, number, start date, end date, all week numbers"
  }
  return (
    <Seo
      title={`Epoch Converter - Week Numbers for ${year}`}
      canonicalUrl={`https://www.epochconverter.io/weeks/${year}`}
      siteMetadata={siteMetadata}
    />
  )
}
